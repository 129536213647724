// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "t_sR d_bD";
export var storyRowWrapper = "t_hx d_hx d_bK";
export var storyLeftWrapper = "t_sS d_bz d_bP";
export var storyWrapperFull = "t_sT d_cD";
export var storyWrapperFullLeft = "t_pj d_cD d_bz d_bP d_bD";
export var contentWrapper = "t_mv d_hy";
export var storyLeftWrapperCenter = "t_sV d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "t_sW d_hF";
export var storyHeader = "t_sX d_hD d_w d_cs";
export var storyHeaderCenter = "t_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "t_hB d_hB d_by d_dw";
export var storyBtnWrapper = "t_sY d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "t_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "t_sZ d_fg d_Z";
export var imageWrapperFull = "t_s0 d_w d_H d_bf d_Z";
export var SubtitleSmall = "t_qb q_qb q_qT q_q4";
export var SubtitleNormal = "t_qc q_qc q_qT q_q5";
export var SubtitleLarge = "t_qd q_qd q_qT q_q6";