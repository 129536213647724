import React from 'react';
import HTMLParser from 'html-react-parser';
import { Link } from 'gatsby';
import clsx from 'clsx';

import { formColor } from '../../../../../helper';
import ButtonEditor from '../../LayoutComponents/ButtonEditor';
import Image from '../../../../Image';
import Logo from '../../../../../images/oidom-logo.svg';
import CustomSectionMedia from '../../LayoutComponents/CustomSectionMedia';

import * as styles from './styles.module.css';

class FooterLayout extends React.Component {
  constructor(props) {
    super(props);

    const full = props.section.styles.full === true ? 'Full' : '';
    let columns;
    if (props.section && props.section.styles && props.section.styles.columns) ({ columns } = props.section.styles);

    let cookie;
    if (
      props.cookie?.consentText &&
      props.cookie?.active &&
      ['discLeft', 'discRight'].includes(props.cookie?.consentPosition)
    ) {
      cookie = {
        text: props.cookie.consentText,
        pos: props.cookie.consentPosition,
        consentLinkColor: props.cookie.consentLinkColor,
        consentLinkOpacity: props.cookie.consentLinkOpacity,
      };
    }

    this.state = {
      section: props.section,
      layout: props.layout,
      full,
      columns,
      nav: props.footerLinks,
      cookie,
    };

    this.createLink = this.createLink.bind(this);
  }

  createLink(l, index, subindex, main) {
    const rawLink = l;
    let link;
    let targetValue;

    if (rawLink.type === 'EXTERNAL') targetValue = rawLink.openLinkInNewTab ? '_blank' : '_self';
    else if (rawLink.type === 'FILE') targetValue = '_blank';

    let button;
    if (rawLink.format === 'BUTTON') {
      button = (
        <ButtonEditor
          themeData={this.props.themeData}
          data={rawLink.formatData}
          text={rawLink.text || rawLink.name || rawLink.path || ''}
        />
      );
    }

    let linkContent = (
      <>
        {rawLink.format === 'IMAGE' && rawLink.formatData && rawLink.formatData.id && (
          <Image
            id={rawLink.formatData.id}
            alt={`Footer_Image_${rawLink.formatData.id}`}
            imageStyleName="navbarImage"
            imageStyle="navbarImage"
            images={this.props.images}
            loadingStrategy={this.props.isFirst ? 'eager' : undefined}
          />
        )}
        {button}
        {rawLink.format === 'ICON' && rawLink.formatData && (
          <i
            className={`entypo ${rawLink.formatData.class}`}
            style={{ fontSize: rawLink.formatData.size, color: rawLink.formatData.color }}
            aria-hidden
          />
        )}
        {rawLink.format === 'TEXT' &&
          ((rawLink.type === 'EXTERNAL' && (rawLink.text || rawLink.path)) ||
            ((rawLink.type === 'ARTICLE' || rawLink.type === 'FILE') && (rawLink.text || rawLink.name)) ||
            rawLink.name)}
      </>
    );

    if (main && l.format === 'TEXT')
      linkContent = (
        <h3 key={`Footer_nav_link_${index}`} className={`footerHeading ${styles.footerHeader}`}>
          {linkContent}
        </h3>
      );
    else if (l.format === 'TEXT')
      linkContent = (
        <p key={`Footer_nav_link_${index}${subindex}`} className={styles.link}>
          {linkContent}
        </p>
      );

    if (rawLink.type === 'EXTERNAL' || rawLink.type === 'FILE') {
      link = (
        <a href={rawLink.path} target={targetValue} rel="noopener noreferrer" draggable="false">
          {linkContent}
        </a>
      );
    } else {
      let url = rawLink.path || '/';
      if (rawLink.type === 'SECTION') url = `${rawLink.path}#${rawLink.sectionSlug}`;
      link = (
        <Link
          to={url}
          state={rawLink.type === 'SECTION' ? { scrollToSection: rawLink.sectionSlug } : undefined}
          draggable="false"
        >
          {linkContent}
        </Link>
      );
    }

    let result = link;
    if (rawLink.format !== 'TEXT')
      result = (
        <div key={`Footer_nav_link_${index}${subindex}`} className={styles.linkWrapper}>
          {link}
        </div>
      );

    return result;
  }

  render() {
    const activeComponents = [];
    const components = [];
    let leftIndex;
    let rightActive = false;
    let leftActive = false;
    this.state.section.data.forEach((item, index) => {
      if (index > 0 && item.type === 'COMPONENT/PERMANENT' && leftIndex === undefined) {
        leftActive = item.active;
        leftIndex = index;
      }
      if (item.type?.startsWith('COMPONENT') && item.active) {
        if (index > 0 && item.type === 'COMPONENT/PERMANENT' && index !== leftIndex) rightActive = true;
        activeComponents.push({ item, index });
      }
    });

    activeComponents.forEach((component, componentIndex) => {
      let bg;
      if (component.item && component.item.styles && component.item.styles.backgroundColor.active) {
        let { backgroundColor } = component.item.styles;
        if (
          component.item.styles.backgroundColor.solid === '' &&
          component.item.styles.backgroundColor.gradient.from === ''
        ) {
          backgroundColor = { solid: '#ffffff' };
        }

        bg = formColor(
          backgroundColor,
          false,
          component.item.styles.backgroundColor.opacity,
          undefined,
          this.props.themeData.colors,
        );
      }

      components.push({
        label: component.item.label,
        index: component.index,
        elements: [],
        style: bg,
        type: component.item.type,
        align: component.item.align,
      });

      component.item.data.forEach((element, elementIndex) => {
        let item;
        let type;
        const align =
          styles[
            `align${component.item.type === 'COMPONENT/PERMANENT' && componentIndex ? 'Disc' : ''}${
              element.align || component.item.align
            }`
          ];

        if (element.active && element.type === 'IMAGES/IMAGE') {
          item = (
            <CustomSectionMedia
              settings={{ align: false, width: [40, 124, 'px'] }}
              key={componentIndex === 0 ? `${this.state.section._id}_layout_${elementIndex}_upper` : ''}
              sizes="16vw"
              className={clsx(styles.media, styles[`item${component.item.align}`])}
              data={element.content}
              images={this.props.images}
              pagePathList={this.props.pagePathList}
              articlePathList={this.props.articlePathList}
              filePathList={this.props.filePathList}
              categoryPathList={this.props.categoryPathList}
              sectionSlugs={this.props.sectionSlugs}
              colors={this.props.themeData.colors}
              loadingStrategy={this.props.isFirst ? 'eager' : undefined}
            />
          );
        } else if (element.active && element.type === 'PARAGRAPH/PARAGRAPH') {
          type = 'paragraph';
          item = <div>{HTMLParser(element.text)}</div>;
        } else if (element.active && element.type === 'HEADINGS/HEADING-THREE') {
          type = 'header';
          item = <h3 className="footerHeading">{HTMLParser(element.text)}</h3>;
        }

        if (item) {
          if (element.type === 'IMAGES/IMAGE') {
            components[componentIndex].elements.push(item);
          } else {
            const editor = (
              <div
                key={componentIndex === 0 ? `${this.state.section._id}_layout_${elementIndex}_upper` : ''}
                className={`${type === 'header' ? styles.footerHeader : ''} ${styles.footerTextWrapper} ${align}`}
              >
                {item}
              </div>
            );

            components[componentIndex].elements.push(editor);
          }
        }
      });
    });

    const { active } = this.state.section.data[0];
    const left = [];
    const right = [];
    const disclaimer = [];

    components.forEach((component) => {
      if (active && component.index === 0) {
        left.push(component);
      } else if (component.index > 0 && component.type === 'COMPONENT') {
        right.push(component);
      } else if (component.index > 0 && component.type === 'COMPONENT/PERMANENT') {
        disclaimer.push(component);
      }
    });

    let className;
    const len = right.length + 1 + (this.state.nav ? this.state.nav.length : 0);
    if (this.state.columns) {
      className = `${styles.colWrapper} ${
        this.state.columns === 1 ? 'col-12' : `col-12 col-sm-6 col-md-${12 / this.state.columns}`
      }`;
    } else if (len === 1) {
      className = `${styles.colWrapper} col-12`;
    } else if (len === 2) {
      className = `${styles.colWrapper} col-12${this.state.layout === 'firstWide' ? '' : ' col-sm-6'}`;
    } else if (len === 3) {
      className = `${styles.colWrapper} col-12 col-sm-6${this.state.layout === 'firstWide' ? '' : ' col-md-4'}`;
    } else {
      className = `${styles.colWrapper} col-12 col-sm-6 col-md-4${
        this.state.layout === 'firstWide' ? '' : ' col-lg-3'
      }`;
    }

    let line;
    if (this.state.layout === 'firstWide') {
      line = <div className={`col-12 ${styles.line}`} />;
    }

    const { cookie } = this.state;
    if (cookie && disclaimer.length === 0) {
      disclaimer.push({});
      if (cookie.pos === 'discRight') disclaimer.push({});
    } else if (cookie && !leftActive) disclaimer.splice(0, 0, {});
    else if (cookie?.pos === 'discRight' && !rightActive) disclaimer.push({});

    let disc;
    if (disclaimer.length > 0 || this.props.badge) {
      const consentLinkColor = cookie?.consentLinkColor
        ? formColor(
            {
              active: 'Solid',
              solid: cookie.consentLinkColor,
            },
            undefined,
            cookie.consentLinkOpacity,
            undefined,
            this.props.themeData.colors,
          )
        : null;

      const texts = [];
      disclaimer.forEach((component, idx) => {
        const text = (
          <div
            className={`col-12${disclaimer.length > 1 ? ' col-sm-6' : ''} ${styles.disclaimer} ${
              styles[`align${component.align}`]
            }`}
            key={`${this.state.section._id}_Component_${component.index}_section`}
            style={component.style || {}}
          >
            {component.elements?.map((element, index) => (
              <div key={`${this.state.section._id}_layout_${index}_lower`}>{element}</div>
            ))}
            {((cookie?.pos === 'discLeft' && idx === 0) || (cookie?.pos === 'discRight' && idx === 1)) && (
              <div className={`defaultTextLink ${styles.consent}`}>
                <a
                  href="#"
                  role="button"
                  style={{ color: consentLinkColor?.backgroundColor }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.onCookieClick();
                  }}
                >
                  {cookie.text}
                </a>
              </div>
            )}
          </div>
        );
        texts.push(text);
      });

      let badge;
      if (this.props.badge?.active) {
        badge = (
          <a
            href={`https://oidom.${this.props.siteLanguage === 'fi' ? 'fi' : 'com'}`}
            target="_blank"
            rel="noopener noreferrer"
            draggable="false"
          >
            <div className={`${styles.badgeContainer}${disclaimer.length === 0 ? ` ${styles.padding}` : ''}`}>
              <div
                style={{
                  color: this.props.badge.colour === 'dark' ? '#000' : '#fff',
                }}
              >
                <p>{this.props.badge.text}</p>
              </div>
              <img src={Logo} alt="oidom badge" className={styles.badge} draggable="false" />
            </div>
          </a>
        );
      }

      let content;
      if (disclaimer.length > 0 && (!this.props.badge || !this.props.badge?.active)) content = texts;
      else if (disclaimer.length === 0 && this.props.badge?.active) content = badge;
      else {
        content = (
          <div className={`row no-gutters ${styles.badgeWrapper}`}>
            <div className={`col-12 col-sm-9 ${styles.badgeDisclaimer}`}>{texts}</div>
            <div className="col-12 col-sm-3">{badge}</div>
          </div>
        );
      }

      disc = (
        <div className={`row ${styles.footerDisclaimerWrapper}${disclaimer.length === 0 ? ` ${styles.end}` : ''}`}>
          {line}
          {content}
        </div>
      );
    }

    const rightContent = [];
    right.forEach((component, index) => {
      const content = (
        <div
          key={`${this.state.section._id}_layout_${index}`}
          className={clsx(
            styles.verticalTop,
            styles[`align${component.align}`],
            this.state.layout === 'firstWide' && component.index === 1 ? 'col-12' : className,
            this.state.layout === 'firstWide' && component.index === 1 && styles.wide,
          )}
          style={component.style}
        >
          {component.elements.map((element, index2) => (
            <React.Fragment key={`${this.state.section._id}_layout_${index}_${index2}`}>{element}</React.Fragment>
          ))}
        </div>
      );
      rightContent.push(content);
    });

    const navContent = [];
    this.state.nav?.forEach((n, index) => {
      const result = [];

      result.push(this.createLink(n, index, undefined, true));
      n.children?.forEach((c, i) => result.push(this.createLink(c, index, i)));

      const content = (
        <div
          key={`${this.props.section._id}_layout_nav_${index}`}
          className={`${
            this.props.layout === 'firstWide' && index === 0 && rightContent.length === 0 ? 'col-12' : className
          }${this.props.layout === 'firstWide' && index === 0 && rightContent.length === 0 ? ` ${styles.wide}` : ''}`}
        >
          {result}
        </div>
      );
      navContent.push(content);
    });

    return (
      <div className={`container ${styles[`footerContainer${this.state.full}`]}`}>
        <div className={`row ${styles[this.state.layout]}`}>
          {active && (
            <div className="col-12 col-md-3">
              <div
                className={`${styles.verticalTop} ${styles[`align${this.state.section.data[0].align}`]}`}
                style={left[0].style}
              >
                {left[0].elements}
              </div>
            </div>
          )}
          <div className={!active ? 'col-12' : 'col-12 col-md-9'}>
            <div className="row">
              {rightContent}
              {navContent}
            </div>
          </div>
        </div>
        {disc}
      </div>
    );
  }
}

export default FooterLayout;
