// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_sJ d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_sK d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_sL d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_sM d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_sN d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_sP d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_sQ d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_rM q_rM";
export var heroExceptionNormal = "s_rN q_rN";
export var heroExceptionLarge = "s_rP q_rP";
export var Title1Small = "s_rn q_rn q_qT q_qV";
export var Title1Normal = "s_rp q_rp q_qT q_qW";
export var Title1Large = "s_rq q_rq q_qT q_qX";
export var BodySmall = "s_rC q_rC q_qT q_rb";
export var BodyNormal = "s_rD q_rD q_qT q_rc";
export var BodyLarge = "s_rF q_rF q_qT q_rd";