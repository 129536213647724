// extracted by mini-css-extract-plugin
export var alignDiscLeft = "m_qf d_fp d_bG d_dv";
export var alignLeft = "m_qg d_fp d_bG d_dv";
export var alignDiscCenter = "m_qh d_fq d_bD d_dw";
export var alignCenter = "m_bP d_fq d_bD d_dw";
export var alignDiscRight = "m_qj d_fr d_bH d_dx";
export var alignRight = "m_qk d_fr d_bH d_dx";
export var footerContainer = "m_ql d_dW d_bW";
export var footerContainerFull = "m_qm d_dT d_bW";
export var footerHeader = "m_kf d_kf";
export var footerTextWrapper = "m_qn d_w";
export var footerDisclaimerWrapper = "m_km d_km d_ck";
export var badgeWrapper = "m_qp d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "m_qq d_bz d_bJ d_bN d_bL";
export var wide = "m_qr d_cy";
export var right = "m_qs d_bK";
export var line = "m_fk d_fl d_cv";
export var badgeDisclaimer = "m_qt d_bC d_bP d_bJ";
export var badgeContainer = "m_qv d_bz d_bH d_bP";
export var badge = "m_qw";
export var padding = "m_qx d_c7";
export var end = "m_qy d_bH";
export var linkWrapper = "m_qz d_dB";
export var link = "m_mC d_dB";
export var colWrapper = "m_qB d_cx";
export var consent = "m_f d_f d_bC d_bP";
export var disclaimer = "m_qC d_bz d_bJ";
export var media = "m_qD d_bx d_dy";
export var itemRight = "m_qF";
export var itemLeft = "m_qG";
export var itemCenter = "m_qH";
export var exceptionWeight = "m_qJ q_rk";